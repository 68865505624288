import { IconProps as ChakraIconProps } from "@chakra-ui/react";
import React from "react";
import {
	Calculator,
	User,
	ExternalLink,
	Phone,
	Laptop,
	Video,
	ArrowLeft,
	Plus,
	Download,
	Flash,
	ColorfulCalculator,
	Edit,
	Call,
	Faq,
} from "src/icons";
import { isRecord } from "src/lib/utils";

type CMSButtonLabelReturn = JSX.Element | null | string;

export const getIcon = (
	icon: string | null | undefined,
	iconProps?: ChakraIconProps,
) => {
	switch (icon) {
		case "calculator":
			return <Calculator {...iconProps} />;

		case "user":
			return <User {...iconProps} />;

		case "phone":
			return <Phone {...iconProps} />;

		case "laptop":
			return <Laptop {...iconProps} />;

		case "video":
			return <Video {...iconProps} />;

		case "left-arrow":
			return <ArrowLeft {...iconProps} />;

		case "plus":
			return <Plus {...iconProps} />;

		case "download":
			return <Download {...iconProps} />;

		case "flash":
			return <Flash {...iconProps} />;

		case "colorful-calculator":
			return <ColorfulCalculator {...iconProps} />;

		case "edit":
			return <Edit {...iconProps} />;

		case "call":
			return <Call {...iconProps} />;

		case "faq":
			return <Faq {...iconProps} />;

		default:
			return null;
	}
};

export const CMSButtonLabel: React.FC<{
	label: string;
	leftIcon?: string | null;
	rightIcon?: string | null;
	isExternal?: boolean;
}> = ({ label, leftIcon, rightIcon, isExternal = false }) => {
	const iconProps: ChakraIconProps = {
		marginRight: 2,
	};

	const externalComponent = isExternal ? (
		<ExternalLink className="external" />
	) : null;

	return (
		<>
			{[
				getIcon(leftIcon, iconProps),
				label,
				getIcon(rightIcon, iconProps),
				externalComponent,
			]
				.filter(Boolean)
				.reduce(
					(
						labelParts: Array<CMSButtonLabelReturn> | null,
						part: CMSButtonLabelReturn,
					) => {
						let key = part?.toString();

						if (isRecord(part)) {
							key =
								part.type.name ?? part.type.displayName ?? key;
						}

						return labelParts === null
							? [
									<React.Fragment key={`${key}-first`}>
										{part}
									</React.Fragment>,
								]
							: [
									...labelParts,
									" ",
									<React.Fragment key={key}>
										{part}
									</React.Fragment>,
								];
					},

					null,
				)}
		</>
	);
};

// 🔬 TBD: unit test?
